import { useContext } from 'react'
import { DataContext } from '@/data_provider'
import { CurrentWeatherData } from '@/models'
import cloudyDay from '@/assets/weather/cloudy-day-2.svg'
import cloudyNight from '@/assets/weather/cloudy-night-2.svg'
import clearDay from '@/assets/weather/day.svg'
import clearNight from '@/assets/weather/night.svg'
import rainyDay from '@/assets/weather/rainy-1.svg'
import rainyNight from '@/assets/weather/rainy-6.svg'
import styles from './CurrentWeather.module.css'

const getWeatherIcon = ({
  weatherCode,
  isDay
}: CurrentWeatherData) => {
  if (isDay) {
    switch (weatherCode) {
      case 0:
      case 1: return clearDay
      case 2:
      case 3: return cloudyDay
      case 45:
      case 48:
      case 51:
      case 53:
      case 55:
      case 61:
      case 63:
      case 65:
      case 80:
      case 81:
      case 82: return rainyDay
      default: return clearDay
    }
  } else {
    switch (weatherCode) {
      case 0: 
      case 1: return clearNight
      case 2: 
      case 3: return cloudyNight
      case 45:
      case 48:
      case 51:
      case 53:
      case 55:
      case 61:
      case 63:
      case 65:
      case 80:
      case 81:
      case 82: return rainyNight
      default: return clearNight
    }
  }
}

// /**
//  * WMO Weather interpretation codes (WW)
//  * Code	Description
//  *   0	Clear sky
//  *   1, 2, 3	Mainly clear, partly cloudy, and overcast
//  *   45, 48	Fog and depositing rime fog
//  *   51, 53, 55	Drizzle: Light, moderate, and dense intensity
//  *   56, 57	Freezing Drizzle: Light and dense intensity
//  *   61, 63, 65	Rain: Slight, moderate and heavy intensity
//  *   66, 67	Freezing Rain: Light and heavy intensity
//  *   71, 73, 75	Snow fall: Slight, moderate, and heavy intensity
//  *   77	Snow grains
//  *   80, 81, 82	Rain showers: Slight, moderate, and violent
//  *   85, 86	Snow showers slight and heavy
//  */
// const getDescription = (code: number): string => {
//   switch (code) {
//     case 0: return "Clear sky"
//     case 1: return "Mainly clear"
//     case 2: return "Partly cloudy"
//     case 3: return "Overcast"
//     case 45: return "Fog"
//     case 48: return "Rime fog"
//     case 51: return "Light drizzle"
//     case 53: return "Moderate drizzle"
//     case 55: return "Dense drizzle"
//     case 61: return "Light rain"
//     case 63: return "Moderate rain"
//     case 65: return "Heavy rain"
//     case 80: return "Slight rain showers"
//     case 81: return "Moderate rain showers"
//     case 82: return "Violent rain showers"
//     default: return ""
//   }
// }

// const getWindy = (wind: number): string => {
//   if (wind > 30) {
//     return "Windy"
//   }
//   return ""
// }

const CurrentWeatherView = (props: CurrentWeatherData) => {
  const { temperature, /* feelsLike */ } = props
  return (
    <div className={styles.container}>
      {/* <div className={styles.stats}>
        <div className={styles.description}>
          <div>{getDescription(weatherCode)}</div>
          <div>{getWindy(wind)}</div>
        </div>
      </div> */}
      <img
        className={styles.weather_icon}
        src={getWeatherIcon(props)}
        alt="weather icon"
      />
      <div className={styles.stats}>
        <div className={styles.temperature}>
          {temperature}°
        </div>
        {/* <div className={styles.feels_like}>
          Feels like <span className={styles.value}>{feelsLike}°</span>
        </div> */}
      </div>
    </div>
  )
}

const CurrentWeather = () => {
  const { data: { weather: { currentWeather }} } = useContext(DataContext)
  if (!currentWeather) return null
  return <CurrentWeatherView {...currentWeather} />
}

export default CurrentWeather
