import { Direction, Route, Stop } from "./models"

export const TRANSIT_LOOKAHEAD_MINUTES = 25
export const TRANSIT_REFRESH_INTERVAL = 1000 * 30
export const METEO_REFRESH_INTERVAL = 1000 * 60 * 3

export const DIRECTIONS = ["Downtown", "Waikiki"] as string[]

export const ROUTES: {[name: string]: Route} = {
  "2": { name: "2", color: "brown" },
  "3": { name: "3", color: "brown" },
  "8": { name: "8", color: "brown" },
  "9": { name: "9", color: "green" },
  "13": { name: "13", color: "brown" },
  "20": { name: "20", color: "green" },
  "23": { name: "23", color: "green" },
  "42": { name: "42", color: "brown" },
  "E": { name: "E", color: "blue" },
}

export const STOPS: Stop[] = [{  // In priority order
  number: 879,
  name: "Ala Moana / Kalia",
  routes: ["20", "23", "42"],
  direction: 0
}, {
  number: 880,
  name: "Ala Moana / Hobron",
  routes: ["E"],
  direction: 0
}, {
  number: 886,
  name: "Kalia / Paoa Pl",
  routes: ["20", "23", "42", "E"],
  direction: 1
}, {
  number: 31,
  name: "Kalakaua / Mccully",
  routes: ["2", "8", "13"],
  direction: 0
}, {
  number: 147,
  name: "Kalakaua / Ena",
  routes  : ["2", "8", "13"],
  direction: 1
}].map(s => ({
  ...s, 
  direction: DIRECTIONS[s.direction] as Direction,
  routes: s.routes.map(r => ROUTES[r])
}))

export const DOWNTOWN_STOPS_NUMBERS = [31, 879, 880]
export const WAIKIKI_STOPS_NUMBERS = [147, 886]
export const STOP_NUMBERS = STOPS.map(s => s.number)
