import { useState, useEffect } from 'react'
import { DateTime } from "luxon"
import { formatTime } from '@/utils'
import styles from './Time.module.css'

const Time = () => {
  const [time, setTime] = useState(DateTime.now())
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(DateTime.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.date_time}>
        <div className={styles.date}>
          {time.weekdayLong}, {time.monthLong} {time.day}
        </div>
        <div className={styles.time}>
          {formatTime(time)}
        </div>
      </div>
    </div>
  )
}

export default Time
