const DEV = import.meta.env.MODE === 'development'
export const DEBUG = DEV
export const API_HOST = DEV ? 'http://localhost:4000' : ''
export const WAIKIKI_COORDS = { lat: 21.281250639027135, lon: -157.83083141789962, }
export const HONOLULU_NOAA_STATION = { id: "1612340", name: "Honolulu", island: "oahu" }
export const TIME_OFFSETS = {
  civilDawn: -23.5,
  civilDusk: 23.5,
  nauticalDawn: -49.5,
  nauticalDusk: 49.5,
  astroDawn: -76.5,
  astroDusk: 76.5
}
export const CARD_SHOW_DELAY = DEBUG ? 0 : 8000
export const CARD_SHOW_INTERVAL = 250
export const LOADING_SPINNER_SIZE = 150
