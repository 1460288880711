import { useMemo } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { StopWithArrivals } from '../api/_helpers/models'
import { DIRECTIONS, TRANSIT_REFRESH_INTERVAL } from '@/api/_helpers/settings'
import { zipmap } from '@/utils'
import { useAxios } from '@/hooks/useFetch'
import { API_HOST } from '@/constants/client_settings'
import styles from './Transit.module.css'

interface TransitColumnProps {
  stops: StopWithArrivals[]
  side: 'left' | 'right'
  direction: string
}

interface TransitProps {
  stops: StopWithArrivals[]
}

const getStopsByDirection = (
  stops: StopWithArrivals[]
): Record<string, StopWithArrivals[]> => {
  return zipmap(
    DIRECTIONS, 
    DIRECTIONS.map(d => stops.filter(s => s.direction === d))
  )
}

export const Stop = ({ stop }: { stop: StopWithArrivals }) => (
  <li>
    <div className={styles.stop}>{stop.name}</div>
    <TransitionGroup component="ul" className={styles.stop_arrivals}>
      {stop.arrivals.map((arrival, i) => (
        <CSSTransition
          key={`${stop.number}_${i}`}
          timeout={700}
          classNames={{
            enterActive: styles.arrival_enter_active,
            enter: styles.arrival_enter,
            exitActive: styles.arrival_exit_active,
            exit: styles.arrival_exit
          }}
        >
          <li>
            <div className={`${styles.arrival_chip} ${styles[arrival.route.color]} ${!arrival.hasGPS ? styles.no_gps : ''}`}>
              <div className={styles.route}>
                {arrival.route.name}
              </div>
              <div className={styles.minutes}>
                {Math.floor(arrival.minutes)} min
              </div>
            </div>
          </li>
        </CSSTransition>
      ))}
    </TransitionGroup>
  </li>
)

const TransitColumn = ({ stops, side, direction }: TransitColumnProps) => (
  <div className={`half card ${side}`}>
    <div className='header'>To {direction}</div>
    <div className='body'>
      <TransitionGroup component="ul" className={styles.stops}>
        {stops.map(stop => (
          <CSSTransition
            key={stop.number}
            timeout={700}
            classNames={{
              enterActive: styles.stop_enter_active,
              enter: styles.stop_enter,
              exitActive: styles.stop_exit_active,
              exit: styles.stop_exit
            }}
          >
            <Stop stop={stop} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  </div>
)

const TransitView = ({ stops }: TransitProps) => {
  const stopsByDirection = useMemo(() => getStopsByDirection(stops), [stops])
  return (
    <div className={styles.transit}>
      {DIRECTIONS.map((d, i) => (
        <TransitColumn
          key={d}
          stops={stopsByDirection[d]}
          side={i === 0 ? 'left' : 'right'}
          direction={d}
        />
      ))}
    </div>
  )
}

const Transit = () => {
  const stops = useAxios(`${API_HOST}/api/transit`, { refreshInterval: TRANSIT_REFRESH_INTERVAL } )
  return stops && (
    <TransitView stops={stops} />
  )
}

export default Transit
