import { useState } from 'react'
import axios from 'axios'
import useSWR from "swr"

const defaultOptions = {
  revalidateOnFocus: true,
  shouldRetryOnError: true
}

export const useFetch = <T>(fetcher: () => Promise<T>, options: any = {}) => {
  const [fetchedData, setFetchedData] = useState<T | null>(null)
  const key = fetcher.toString()  // Create a unique key for each fetcher
  const { error } = useSWR(key, () => fetcher().then(setFetchedData), {...defaultOptions, ...options})
  if (error) throw error
  return fetchedData
}

export const useAxios = (url: string, options: any = {}) => {
  const [fetchedData, setFetchedData] = useState(null)
  const fetcher = (url: string) => {
    axios.get(url).then(res => res.data).then(setFetchedData)
  }  
  const { error } = useSWR(url, fetcher, {...defaultOptions, ...options})
  if (error) throw error
  return fetchedData
}
