import { ReactNode, useEffect, useState, cloneElement, useCallback } from "react"
import RingLoader from "react-spinners/RingLoader"
import {
  CARD_SHOW_DELAY, CARD_SHOW_INTERVAL, LOADING_SPINNER_SIZE
} from "@/constants/client_settings"
import styles from "./LoadSequence.module.css"

const LoadingScreen = () => (
  <div >
    <div className={styles.title}>
      <h1>Display Dashboard</h1>
    </div>
    <div className={styles.spinner} style={{
      paddingRight: LOADING_SPINNER_SIZE / 4,
      paddingBottom: LOADING_SPINNER_SIZE / 4
    }}>
      <RingLoader size={LOADING_SPINNER_SIZE} />
    </div>
    <div className={styles.bottom}>
      <div className={styles.location}>
        <h2>North Waikiki</h2>
      </div>
      <div className={styles.notes}>
        <p>
          Designed for display on a large screen (e.g. TV or monitor)
        </p>
        <p className='font-bold'>
          Created by {' '}
          <a href="https://www.kenfehling.com" target="_blank" rel="noreferrer noopener">
            Ken Fehling
          </a>
        </p>
      </div>
    </div>
  </div>
)

const CardLoader = ({children}: {children: ReactNode}) => {
  const [show, setShow] = useState(false)
  const [cardIndex, setCardIndex] = useState(0)

  const cards = children instanceof Array ? children : [children]
  const cardCount = cards.length

  const showNextCard = useCallback(() => {
    if (cardIndex >= cardCount) {
      return
    }
    setCardIndex(cardIndex + 1)
  }, [cardIndex, cardCount])

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, CARD_SHOW_DELAY)
  })

  useEffect(() => {
    if (show) {
      if (cardIndex >= cardCount) {
        return
      }
      setTimeout(showNextCard, CARD_SHOW_INTERVAL)
    }
  }, [show, cardIndex, cardCount, showNextCard])

  return (
    <>
      {cards.map((card, index) => cloneElement(card, {
        key: index,
        style: {opacity: index < cardIndex ? 1 : 0}
      }))}
      {!show && <LoadingScreen />}
    </>
  )
}

export default CardLoader
