import { useContext } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { DataContext } from '@/data_provider'
import { WindData } from '@/models'
import { chartContainerStyle, commonBarProps } from '@/constants/plot_settings'

const WindView = ({wind}: {wind: WindData}) => (
  <div className='plot'>
    <div style={chartContainerStyle}>
      <ResponsiveBar
        data={wind}
        keys={["value"]}
        colors={['#FFFFFF20']}
        valueScale={{
          type: 'linear',
          min: 0,
          max: 100
        }}
        axisLeft={{
          tickSize: 0,
          format: (y: number) => {
            if (y % 20 === 10) return y
            return ''
          },
          tickPadding: 12
        }}
        {...commonBarProps}
      />
    </div>
  </div>
)

const Wind = () => {
  const { data: { weather: { wind }} } = useContext(DataContext)
  if (!wind) return null
  return <WindView wind={wind} />
}

export default Wind
