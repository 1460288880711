/// <reference types="vite-plugin-svgr/client" />
import MapImage from '../assets/map.svg?react'
import styles from './Map.module.css'

const Map = () => (
  <div className={`svg-image ${styles.map_image}`}>
    <MapImage />
  </div>
)

export default Map
