import { DateTime } from "luxon"

export function zipmap<T>(arr1: string[], arr2: T[]): Record<string, T> {
  return arr1.reduce((p, c, i) => {
    p[c] = arr2[i]
    return p
  }, {} as Record<string, T>)
}

export function isClose(a: number, b: number, epsilon = 0.0001) {
  return Math.abs(a - b) < epsilon
}

const zeroPad = (num: number) => num.toString().padStart(2, '0')

export const formatTime = (time: DateTime) => {
  return `${time.hour % 12 || 12}:${zeroPad(time.minute)} ${time.hour < 12 ? 'AM' : 'PM'}`
}
