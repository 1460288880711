import { useContext } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { DataContext } from '@/data_provider'
import { PrecipitationData } from '@/models'
import { chartContainerStyle, commonBarProps } from '@/constants/plot_settings'
import { isClose } from '@/utils'

const axisLabels = {
  0.4: 'L',
  1: 'H'
}

const PrecipitationView = ({precipitation}: {precipitation: PrecipitationData}) => (
  <div className='plot'>
    <div style={chartContainerStyle}>
      <ResponsiveBar
        data={precipitation}
        keys={["value"]}
        colors={['#00B3FF']}
        valueScale={{
          type: 'linear',
          min: 0,
          max: 1.5
        }}
        axisLeft={{
          tickSize: 0,
          format: (y: number) => {
            for (const [key, value] of Object.entries(axisLabels)) {
              if (isClose(y, Number(key))) return value
            }
            return ''
          },
          tickPadding: 12
        }}
        {...commonBarProps}
      />
    </div>
  </div>
)

const Precipitation = () => {
  const { data: { weather: { precipitation }} } = useContext(DataContext)
  if (!precipitation) return null
  return <PrecipitationView precipitation={precipitation} />
}

export default Precipitation
