import { useContext } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { DataContext } from '@/data_provider'
import { UvIndexData } from '@/models'
import { chartContainerStyle, commonBarProps } from '@/constants/plot_settings'

const axisLabels = [1, 3, 6, 8, 11]

const UvIndexView = ({uvIndex}: {uvIndex: UvIndexData}) => (
  <div className='plot'>
    <div style={chartContainerStyle}>
      <ResponsiveBar
        data={uvIndex}
        keys={["value"]}
        colors={['#FFC044']}
        valueScale={{
          type: 'linear',
          min: 0,
          max: 11
        }}
        axisLeft={{
          tickSize: 0,
          format: (y: number) => {
            if (axisLabels.indexOf(y) >= 0) return y
            return ''
          },
          tickPadding: 12
        }}
        {...commonBarProps}
      />
    </div>
  </div>
)

const UvIndex = () => {
  const { data: { weather: { uvIndex }} } = useContext(DataContext)
  if (!uvIndex) return null
  return <UvIndexView uvIndex={uvIndex} />
}

export default UvIndex
