import { useContext } from 'react'
import { DataContext } from '@/data_provider'
import { ResponsiveLine } from '@nivo/line'
import { TemperatureData, TemperatureDatum } from '@/models'
import { 
  chartContainerStyle as chartContainerStyle , commonLineProps
} from '@/constants/plot_settings'

type TemperatureViewProps = {
  temperature: TemperatureData
  tempMax: number
  tempMin: number
}

type PlotLayerProps = {
  data: TemperatureDatum
  color: string
  lineWidth: number
  tempMax: number
  tempMin: number
  axisBottom?: boolean
}

const getMax = (data: TemperatureData): number => {
  return Math.max(...data.flatMap(d => d.data.map((d) => d.y)))
}

const getMin = (data: TemperatureData): number => {
  return Math.min(...data.flatMap(d => d.data.map((d) => d.y)))
}

const PlotLayer = ({data, color, lineWidth, tempMax, tempMin, axisBottom=true}: PlotLayerProps) => (
  <div className='plot-layer'>
    <div style={chartContainerStyle}>
      <ResponsiveLine
        data={[data]}
        margin={{
          top: 16,
          right: 36,
          bottom: 20,
          left: 46
        }}
        yScale={{
          type: 'linear',
          max: tempMax + 1,
          min: tempMin - 2
        }}
        axisLeft={{
          tickSize: 0,
          format: (y: number) => {
            if (y % 4 === 0) return y
            return ''
          },
          tickPadding: 22
        }}
        colors={[color]}
        lineWidth={lineWidth}
        {...commonLineProps}
        axisBottom={axisBottom ? commonLineProps.axisBottom : {
          format: () => ''
        }}
      />
    </div>
  </div>
)

const TemperatureView = ({temperature, tempMax, tempMin}: TemperatureViewProps) => (
  <div className='plot'>
    <PlotLayer
      data={temperature[0]}
      color='#FFC044'
      lineWidth={4}
      tempMax={tempMax}
      tempMin={tempMin}
      axisBottom
    />
    <PlotLayer
      data={temperature[1]}
      color='#FF9911'
      lineWidth={2}
      tempMax={tempMax}
      tempMin={tempMin}
    />
  </div>
)

const Temperature = () => {
  const { data: { weather: { temperature }} } = useContext(DataContext)
  if (!temperature) return null
  const tempMax = getMax(temperature)
  const tempMin = getMin(temperature)
  return (
    <TemperatureView
      temperature={temperature}
      tempMax={tempMax}
      tempMin={tempMin}
    />
  )
}

export default Temperature
