import { CSSProperties } from "react"

export const theme = {
  "axis": {
    "ticks": {
      "line": {
        "stroke": 'null'
      }
    }
  }
}

const commonProps = {
  theme,
  enableGridX: false,
  enableGridY: false,
  isInteractive: false,
  motionConfig: "molasses",
  axisBottom: {
    tickSize: 0,
    tickPadding: 8,
    format: (t: number) => {
      const date = new Date(t)
      const h = date.getHours()
      const m = date.getMinutes()
      return h % 4 === 0 && m === 0 ? (h % 12 || 12) + (h < 12 ? ' AM' : ' PM') : ""
    }
  }
}

export const commonLineProps = {
  ...commonProps,
  curve: 'basis' as const,
  enablePoints: false
}

export const commonBarProps = {
  ...commonProps,
  margin: {
    top: 16,
    right: 24,
    bottom: 20,
    left: 36
  },
  indexScale: {
    type: 'band' as const,
    round: false
  },
  indexBy: 'time',
  enableLabel: false,
  borderRadius: 10,
  padding: 0.25,
}

export const noAxis = {
  tickSize: 0,
  format: () => ""
}

export const chartContainerStyle = { 
  height: '96%',
  width: '100%',
  boxSizing: 'border-box' as CSSProperties['boxSizing'],
  overflow: 'hidden'
}
