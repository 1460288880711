import './App.css'
import Time from './components/Time'
import Transit from './components/Transit'
import Precipitation from './components/Precipitation'
import Temperature from './components/Temperature'
import { DataProvider } from './data_provider'
import UvIndex from './components/UvIndex'
import LoadSequence from './components/LoadSequence'
import Wind from './components/Wind'
import Solar from './components/Solar'
import CurrentWeather from './components/CurrentWeather'
import Map from './components/Map'

const App = () => (
  <DataProvider>
    <div className='root'>
      <LoadSequence>
        <div className='card half-col'>
          <div className='header'>Time</div>
          <div className='body'><Time /></div>
        </div>

        <div className='card half-col'>
          <div className='header'>Solar</div>
          <div className='body'><Solar /></div>
        </div>

        <div className='card half-col'>
          <div className='header'>Current Weather</div>
          <div className='body'><CurrentWeather /></div>
        </div>

        <div className='card half-col'>
          <div className='header'>Area Map</div>
          <div className='body'><Map /></div>
        </div>

        <div className='transit-col'>
          <Transit />
        </div>

        <div className='card weather-col'>
          <div className='header'>Precipitation Intensity</div>
          <div className='body'><Precipitation /></div>
        </div>

        <div className='card weather-col'>
          <div className='header'>Temperature / Apparent Temperature</div>
          <div className='body'><Temperature /></div>
        </div>

        <div className='card weather-col'>
          <div className='header'>Wind Gusts</div>
          <div className='body'><Wind /></div>
        </div>

        <div className='card weather-col'>
          <div className='header'>UV Index</div>
          <div className='body'><UvIndex /></div>
        </div>
      </LoadSequence>
    </div>
  </DataProvider>
)

export default App
